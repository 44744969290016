import {render} from "react-dom";
import {StrictMode} from "react";
import {Landing} from "app/landing/Landing";
import {initI18n} from "app/employee/i18n";
import {I18nextProvider} from "react-i18next";
import {StyleSheetManager} from "styled-components";
import {forwardAllProps} from "app/utils/stdlib";

(() => {
  initI18n().then((i18n) => {
    render(
      <StrictMode>
        <StyleSheetManager enableVendorPrefixes shouldForwardProp={forwardAllProps}>
          <I18nextProvider i18n={i18n}>
            <Landing />
          </I18nextProvider>
        </StyleSheetManager>
      </StrictMode>,
      document.getElementById("container")
    );
  });
})();
